import { ItemMessagesFilters } from '../../components/list/ItemMessagesList';

interface IsChatGptMessagesModeOptions {
  messagesFilters: ItemMessagesFilters;
}

function isChatGptMessagesMode({
  messagesFilters
}: IsChatGptMessagesModeOptions): boolean {
  return !!messagesFilters?.chatGptTargetUserId?.eq;
}

export default isChatGptMessagesMode;
