import React from 'react';
import size from 'lodash/size';
import cl from 'classnames';

import { ItemMessagesListMessageMenuMessage } from './ItemMessagesListMessageMenu.types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import {
  FetchMessagesCacheKey,
  MessageID,
  MessageMessageTypes,
  MessageProjectUUID,
  MessageTaskUUID,
  OnEditMessageAction
} from '../../../../../messagesTypes';
import { CheckableMessagesProps } from '../../../../../../../common/hooks/useMessagesCheckable';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { ItemMessagesListMessageMenuDropdown } from '../ItemMessagesListMessageMenuDropdown';
import { ItemMessagesListMessageLikesPopover } from '../ItemMessagesListMessageLikesPopover';
import { ItemMessagesListMessageDownloadFileAttachmentsButton } from '../ItemMessagesListMessageDownloadFileAttachmentsButton';
import { PublishMessageButton } from '../../../../buttons/PublishMessageButton';
import { ReplyMessageButton } from '../../../../buttons/ReplyMessageButton';

import { MessageMenuEditButton } from './components/MessageMenuEditButton';
import { MessageMenuPinnedButton } from './components/MessageMenuPinnedButton';
import { MessageMenuVFCButton } from './components/MessageMenuVFCButton';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { TooltipSingletonSourceWrapper } from '../../../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { MessagesPermissions } from '../../../../../messagesConstants';

import {
  attachmentsKeys,
  messagesKeys,
  words
} from '../../../../../../../locales/keys';

type ItemMessagesListMessageMenuProps = {
  isLeft?: boolean;
  message: ItemMessagesListMessageMenuMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  onReplyMessage: (messageId: MessageID) => void;
  forwardMessageProjectUuid?: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  withEditMessage?: boolean;
  withoutDropdownMenu?: boolean;
  withoutPropagateButton?: boolean;
  onEditMessage?: OnEditMessageAction;
} & CheckableMessagesProps;

function ItemMessagesListMessageMenu({
  isLeft,
  message,
  messagesCacheKey,
  onReplyMessage,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  withEditMessage,
  withoutDropdownMenu,
  withoutPropagateButton,
  onEditMessage,
  checkedMessages,
  onSetCheckedMessage
}: ItemMessagesListMessageMenuProps) {
  const currentUser = useCurrentUser();

  return (
    <TooltipSingletonSourceWrapper placement={TooltipPlacement.TOP} withArrow>
      <div
        className={cl(
          'flex sm:opacity-0 group-hover:opacity-100 focus-within:opacity-100 self-stretch items-center space-x-0.5',
          {
            'order-first flex-row-reverse mr-2 space-x-reverse': isLeft,
            'ml-2': !isLeft
          }
        )}
      >
        <CheckPermissions
          action={MessagesPermissions.READ_MESSAGE_LIKES_BUTTON}
        >
          <ItemMessagesListMessageLikesPopover
            messageUuid={message.uuid}
            messagesCacheKey={messagesCacheKey}
            tooltipSingleton
          />
        </CheckPermissions>

        <div
          className={cl('sm:flex space-x-0.5 hidden', {
            'flex-row-reverse space-x-reverse': isLeft
          })}
        >
          {!message.body && size(message.fileAttachments) > 0 ? (
            <CheckPermissions
              action={
                MessagesPermissions.READ_MESSAGE_DOWNLOAD_FILE_ATTACHMENTS_BUTTON
              }
            >
              <ItemMessagesListMessageDownloadFileAttachmentsButton
                uuid={message.uuid}
                tooltipI18nText={
                  size(message.fileAttachments) > 1
                    ? words.downloadAll
                    : attachmentsKeys.download
                }
                tooltipSingleton
              />
            </CheckPermissions>
          ) : (
            <CheckPermissions
              action={MessagesPermissions.READ_MESSAGE_REPLY_BUTTON}
            >
              <ReplyMessageButton
                messageId={message.id}
                onReplyMessage={onReplyMessage}
                icon={IconsEnum.REPLY_OUTLINE}
                tooltipI18nText={messagesKeys.reply}
              />
            </CheckPermissions>
          )}

          {message.body &&
            message.messageType ===
              MessageMessageTypes.ANSWER_CHATGPT_DISCUSSION_MESSAGE && (
              <CheckPermissions
                action={MessagesPermissions.READ_MESSAGE_PUBLISH_BUTTON}
              >
                <PublishMessageButton
                  icon={IconsEnum.EXTERNAL_LINK_SOLID}
                  message={message}
                  tooltipI18nText={words.publish}
                />
              </CheckPermissions>
            )}

          {withEditMessage &&
          onEditMessage &&
          currentUser.nanoId === message.user?.nanoId ? (
            <MessageMenuEditButton
              message={message}
              onEditMessage={onEditMessage}
            />
          ) : null}

          <CheckPermissions
            action={MessagesPermissions.READ_MESSAGE_PINNED_BUTTON}
          >
            <MessageMenuPinnedButton
              message={message}
              messagesCacheKey={messagesCacheKey}
            />
          </CheckPermissions>

          <CheckPermissions
            action={MessagesPermissions.READ_MESSAGE_VISIBLE_FOR_CLIENTS_BUTTON}
          >
            <MessageMenuVFCButton
              message={message}
              messagesCacheKey={messagesCacheKey}
            />
          </CheckPermissions>
        </div>

        {withoutDropdownMenu ? null : (
          <ItemMessagesListMessageMenuDropdown
            message={message}
            messagesCacheKey={messagesCacheKey}
            onReplyMessage={onReplyMessage}
            forwardMessageProjectUuid={forwardMessageProjectUuid}
            forwardMessageTaskUuid={forwardMessageTaskUuid}
            withPropagateButton={!withoutPropagateButton}
            tooltipSingleton
            checkedMessages={checkedMessages}
            onSetCheckedMessage={onSetCheckedMessage}
          />
        )}
      </div>
    </TooltipSingletonSourceWrapper>
  );
}

export default ItemMessagesListMessageMenu;
