import { useCallback, useEffect, useMemo, useState } from 'react';
import filter from 'lodash/filter';
import find from 'lodash/find';
import delay from 'lodash/delay';
import includes from 'lodash/includes';
import omit from 'lodash/omit';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useItemCreateMessageFormAddLifestyleFromLink } from '../useItemCreateMessageFormAddLifestyleFromLink';
import { useItemCreateMessageFormAddProductFromLink } from '../useItemCreateMessageFormAddProductFromLink';
import { usePreviousValue } from '../../../../../../../common/hooks/usePreviousValue';
import { useProcessMessageCommand } from '../../../../../hooks/useProcessMessageCommand';
import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import {
  MessageFields,
  MessageID,
  MessageMessageTypes,
  MessageUUID
} from '../../../../../messagesTypes';
import { ItemCreateMessageFormSubmit } from '../../ItemCreateMessageForm.types';
import {
  ItemMessagesFilters,
  ItemMessagesListFilterMessages,
  ItemMessagesListMessages
} from '../../../../list/ItemMessagesList';
import {
  ItemCreateMessageFormChange,
  ItemCreateMessageFormData
} from './useItemCreateMessageForm.types';
import { ProjectID } from '../../../../../../projects/projectsTypes';
import { TaskID } from '../../../../../../tasks/tasksTypes';
import { itemCreateMessageFormValidationSchema } from './useItemCreateMessageForm.schema';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { isEveryPropEmpty } from '../../../../../../../utils/isEveryPropEmpty';

import { chatGptMessagesTypes } from '../../../../../messagesConstants';

const baseDefaultValues: ItemCreateMessageFormData = {
  attachedFileAttachmentIds: [],
  body: '',
  colors: [],
  fileAttachmentIds: [],
  lexical: { text: '', mentionIds: [] },
  repliedMessageId: null,
  selectedLifestyleIds: [],
  selectedMaterialIds: [],
  selectedProductIds: [],
  uuid: '' as MessageUUID,
  visibleForClient: false
};

interface useItemCreateMessageFormProps {
  filterMessages?: ItemMessagesListFilterMessages;
  isChatGptMode?: boolean;
  lexicalWithFormatting?: boolean;
  messageInitialValues: Partial<ItemCreateMessageFormData>;
  messages?: ItemMessagesListMessages;
  messagesFilters?: ItemMessagesFilters;
  onAfterChatGptCommand?: () => void;
  onChange?: ItemCreateMessageFormChange;
  onSubmit: ItemCreateMessageFormSubmit;
  sendToProjectId?: ProjectID;
  sendToTaskId?: TaskID;
  withAttachLifestylesButton?: boolean;
  withAttachProductsButton?: boolean;
  withLexical?: boolean;
}

function useItemCreateMessageForm({
  filterMessages,
  isChatGptMode,
  lexicalWithFormatting,
  messageInitialValues,
  messages,
  messagesFilters = {},
  onAfterChatGptCommand,
  onChange,
  onSubmit,
  sendToProjectId,
  sendToTaskId,
  withAttachLifestylesButton,
  withAttachProductsButton,
  withLexical
}: useItemCreateMessageFormProps) {
  const defaultValues = useMemo<ItemCreateMessageFormData>(() => {
    return messageInitialValues
      ? {
          ...baseDefaultValues,
          ...messageInitialValues
        }
      : baseDefaultValues;
  }, [messageInitialValues]);

  const currentUser = useCurrentUser();

  const prevRepliedMessageId = usePreviousValue(
    messageInitialValues?.repliedMessageId
  );

  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    resetForm,
    getValues,
    setValue,
    watch
  } = useReactHookForm<ItemCreateMessageFormData>({
    defaultValues,
    schema: itemCreateMessageFormValidationSchema
  });

  const {
    commandProcessing,
    handleChatGptCommand,
    handleSetUploadedFile,
    handleSetRepliedMessageFile,
    messageCommand
  } = useProcessMessageCommand();

  const handleSetRepliedMessagePdfFile = useCallback<
    (repliedMessageId: MessageID) => void
  >(
    (repliedMessageId) => {
      const repliedMessage = find(messages, {
        id: repliedMessageId
      });

      const firstPdfOrImageUrl = filter(
        repliedMessage?.fileAttachments,
        ({ ext }) => includes(['pdf', 'png', 'gif', 'jpg', 'jpeg'], ext)
      )?.[0]?.file;

      handleSetRepliedMessageFile(firstPdfOrImageUrl);
    },
    [messages, handleSetRepliedMessageFile]
  );

  const switchToChatGptMode = useCallback<() => void>(() => {
    filterMessages?.({
      ...messagesFilters,
      messageTypeTextFilter: { in: chatGptMessagesTypes },
      chatGptTargetUserId: { eq: currentUser?.id }
    });
  }, [currentUser?.id, filterMessages, messagesFilters]);

  useEffect(() => {
    if (prevRepliedMessageId !== messageInitialValues?.repliedMessageId) {
      handleSetRepliedMessagePdfFile(messageInitialValues?.repliedMessageId);

      setValue(
        MessageFields.REPLIED_MESSAGE_ID,
        messageInitialValues?.repliedMessageId
      );
    }
  }, [
    messages,
    handleSetRepliedMessagePdfFile,
    messageInitialValues?.repliedMessageId,
    prevRepliedMessageId,
    setValue
  ]);

  const { detectLifestyle, resetDetectedLifestyle } =
    useItemCreateMessageFormAddLifestyleFromLink({
      control,
      setValue
    });

  const { detectProduct, resetDetectedProduct } =
    useItemCreateMessageFormAddProductFromLink({
      control,
      setValue
    });

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (name === MessageFields.LEXICAL) {
        withAttachProductsButton && detectProduct(data.lexical);
        withAttachLifestylesButton && detectLifestyle(data.lexical);
      }
    });
    return () => subscription.unsubscribe();
  }, [
    watch,
    detectLifestyle,
    detectProduct,
    withAttachProductsButton,
    withAttachLifestylesButton
  ]);

  const [isEditorFocused, setIsEditorFocused] = useState<boolean>(true);

  const handleEditorFocus = useCallback<() => void>(() => {
    setIsEditorFocused(true);
  }, []);

  const handleEditorBlur = useCallback<() => void>(() => {
    setIsEditorFocused(false);
  }, []);

  const [isEditorEmpty, setIsEditorEmpty] = useState<boolean>(() =>
    isEveryPropEmpty(defaultValues, ['visibleForClient', 'uuid'])
  );

  useEffect(() => {
    const subscription = watch((data) => {
      setIsEditorEmpty(isEveryPropEmpty(data, ['visibleForClient', 'uuid']));

      onChange?.({ data: data as ItemCreateMessageFormData });
    });

    return () => subscription.unsubscribe();
  }, [watch, onChange]);

  return {
    validationErrors: {
      bodyValidationError: errors?.body?.message
    },
    control,
    commandProcessing,
    resetForm,
    handleEditorBlur,
    handleEditorFocus,
    handleSetUploadedFile,
    handleSubmitReactHookForm,
    handleSubmitCreateMessageForm: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (data) => {
        resetForm();
        resetDetectedLifestyle();
        resetDetectedProduct();

        const lexicalText = data.lexical?.text || '';

        const bodyWithFormatting = lexicalWithFormatting
          ? lexicalText
          : lexicalText.replace(/^```markdown\n/, '').replace(/\n```$/, '');

        const body = withLexical ? bodyWithFormatting : data.body;

        const mentionedUserIds = data.lexical?.mentionIds;

        const messageType =
          messageCommand || isChatGptMode
            ? MessageMessageTypes.REQUEST_CHATGPT_DISCUSSION_MESSAGE
            : undefined;

        const muted = messageCommand || isChatGptMode ? true : undefined;

        await onSubmit({
          ...omit(data, ['lexical']),
          messageType,
          muted,
          body,
          mentionedUserIds,
          uuid: generateUuid()
        });

        if (!isChatGptMode && messageCommand) {
          switchToChatGptMode();
        }

        if (isChatGptMode || messageCommand) {
          await handleChatGptCommand({
            userContentText: body,
            projectId: sendToProjectId,
            taskId: sendToTaskId
          });

          onAfterChatGptCommand && delay(onAfterChatGptCommand, 3000);
        }

        //----------------------------------------------
        // const { messageBody, todoItems } = await handleMessageCommand(
        //   body,
        //   data.visibleForClient
        // );

        // return onSubmit({
        //   ...omit(data, ['lexical']),
        //   body: messageBody,
        //   mentionedUserIds,
        //   uuid: generateUuid(),
        //   todoItems
        // });
      }
    }),
    isEditorActive: isEditorFocused || messageCommand || !isEditorEmpty,
    isEditorEmpty,
    messageBody: getValues(MessageFields.BODY),
    messageCommand,
    messageVisibleForClient: getValues(MessageFields.VISIBLE_FOR_CLIENT),
    registerFields: {
      registerBody: register(MessageFields.BODY),
      registerLexical: register(MessageFields.LEXICAL),
      registerVisibleForClient: register(MessageFields.VISIBLE_FOR_CLIENT)
    },
    setValue
  };
}

export default useItemCreateMessageForm;
