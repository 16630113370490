import React from 'react';
import size from 'lodash/size';
import cl from 'classnames';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { ItemMessagesListMessageAttachmentsAmountMessage } from './ItemMessagesListMessageAttachmentsAmount.types';

import { Translate } from '../../../../../../../helpers/Translate';
import { Icon } from '../../../../../../../helpers/Icon';

import { attachmentsKeys } from '../../../../../../../locales/keys';

interface ItemMessagesListMessageAttachmentsAmountProps {
  message: ItemMessagesListMessageAttachmentsAmountMessage;
  reverse?: boolean;
}

function ItemMessagesListMessageAttachmentsAmount({
  message,
  reverse
}: ItemMessagesListMessageAttachmentsAmountProps) {
  const fileAttachments = message.fileAttachments || [];

  const selectedProducts = message.selectedProducts || [];
  const selectedLifestyles = message.selectedLifestyles || [];
  const selectedMaterials = message.selectedMaterials || [];
  const attachmentsAmount = size([
    ...fileAttachments,
    ...selectedProducts,
    ...selectedLifestyles,
    ...selectedMaterials
  ]);

  if (!attachmentsAmount) {
    return null;
  }

  return (
    <div
      className={cl('flex', {
        'flex-row-reverse': reverse
      })}
    >
      <div className="flex items-center gap-x-0.5 pb-1 text-2xs text-gray-500 dark:text-gray-400">
        <Icon icon={IconsEnum.PAPER_CLIP_OUTLINE} className="h-4 w-4" />
        <span className="text-xs">
          {attachmentsAmount}
          &nbsp;
          <Translate id={attachmentsKeys.pluralGenitive} />
        </span>
      </div>
    </div>
  );
}

export default ItemMessagesListMessageAttachmentsAmount;
