import { gql } from 'graphql-request';

import {
  ChatGptCompletionID,
  ChatGptCompletionMessageContent,
  ChatGptCompletionMessageRole,
  ChatGptCompletionMessages,
  ChatGptCompletionModel,
  ChatGptCompletionResponseBody,
  ChatGptCompletionUUID
} from '../chatGptCompletionsTypes/chatGptCompletionsFieldsTypes';

export type ChatGptCompletionResponseBodyType = {
  id: ChatGptCompletionID;
  model: ChatGptCompletionModel;
  choices: {
    message: {
      role: ChatGptCompletionMessageRole;
      content: ChatGptCompletionMessageContent;
    };
    finish_reason: string;
  }[];
};

export interface CreateChatGptCompletionQueryResponse {
  id: ChatGptCompletionID;
  uuid: ChatGptCompletionUUID;
  messages: ChatGptCompletionMessages;
  responseBody: ChatGptCompletionResponseBody;
}

export const CREATE_CHAT_GPT_COMPLETION_QUERY = gql`
  mutation CreateChatGptCompletion(
    $url: String!
    $model: String!
    $messages: JSON!
    $options: JSON
    $projectId: ID
    $taskId: ID
  ) {
    createChatGptCompletion(
      input: {
        url: $url
        model: $model
        messages: $messages
        options: $options
        projectId: $projectId
        taskId: $taskId
      }
    ) {
      errors {
        fullMessages
      }
      recordUuid
      status
      record {
        id
        uuid
        messages
        responseBody
      }
    }
  }
`;
