import {
  MessageAttachmentTypes,
  MessageFinAttachmentTypes
} from '../../../../../../../messagesTypes';
import {
  ItemMessagesListMessageImageFileAttachmentAttachment,
  ItemMessagesListMessageImageSelectedProductAttachment,
  ItemMessagesListMessageImageSelectedLifestyleAttachment,
  ItemMessagesListMessageImageSelectedMaterialAttachment
} from '../../index';

function mapMessageAttachmentImage(
  attachment:
    | ItemMessagesListMessageImageFileAttachmentAttachment
    | ItemMessagesListMessageImageSelectedProductAttachment
    | ItemMessagesListMessageImageSelectedLifestyleAttachment
    | ItemMessagesListMessageImageSelectedMaterialAttachment
) {
  switch (attachment.type) {
    case MessageAttachmentTypes.FILE_ATTACHMENT:
      return attachment;
    case MessageAttachmentTypes.SELECTED_PRODUCT:
      return { ...attachment.product?.image, type: attachment.type };
    case MessageAttachmentTypes.SELECTED_LIFESTYLE:
      return { ...attachment.lifestyle?.image, type: attachment.type };
    case MessageAttachmentTypes.SELECTED_MATERIAL:
      return { ...attachment.material?.image, type: attachment.type };
    case MessageFinAttachmentTypes.FILE_ATTACHMENT:
      return attachment;
    case MessageFinAttachmentTypes.SELECTED_PRODUCT:
      return { ...attachment.product?.image, type: attachment.type };
    case MessageFinAttachmentTypes.SELECTED_LIFESTYLE:
      return { ...attachment.lifestyle?.image, type: attachment.type };
    case MessageFinAttachmentTypes.SELECTED_MATERIAL:
      return { ...attachment.material?.image, type: attachment.type };
  }
}

export default mapMessageAttachmentImage;
