import React from 'react';
import sortBy from 'lodash/sortBy';
import size from 'lodash/size';

import { ItemMessagesListMessageRepliedMessageAttachmentsMessage } from './ItemMessagesListMessageRepliedMessageAttachments.types';
import {
  MessageAttachmentTypes,
  MessageFinAttachmentTypes
} from '../../../../../../../messagesTypes';

import { ItemMessagesListMessageRepliedMessageFile } from '../ItemMessagesListMessageRepliedMessageFile';
import { ItemMessagesListMessageRepliedMessageImage } from '../ItemMessagesListMessageRepliedMessageImage';

import { Files } from '../../../../../../../../../utils/Files';

const showFilesCount = 3;
const lastShownFilesIndex = showFilesCount - 1;

interface ItemMessagesListMessageRepliedMessageAttachmentsProps {
  message: ItemMessagesListMessageRepliedMessageAttachmentsMessage;
}

function ItemMessagesListMessageRepliedMessageAttachments({
  message
}: ItemMessagesListMessageRepliedMessageAttachmentsProps) {
  const fileAttachments = message.repliedMessage?.fileAttachments || [];

  const selectedProducts = message.repliedMessage?.selectedProducts || [];
  const selectedLifestyles = message.repliedMessage?.selectedLifestyles || [];
  const selectedMaterials = message.repliedMessage?.selectedMaterials || [];
  const messageFiles = sortBy(
    [
      ...fileAttachments,
      ...selectedProducts,
      ...selectedLifestyles,
      ...selectedMaterials
    ],
    (attachment) => attachment.createdAt
  );

  const messageFilesCount = size(messageFiles);

  return (
    <div className="overflow-y-hidden overscroll-x-auto">
      <div className="gap-1 grid grid-flow-col justify-start pb-1">
        {messageFiles.map((messageFile, index) => {
          if (index > lastShownFilesIndex) {
            return null;
          }

          return (messageFile.type === MessageAttachmentTypes.FILE_ATTACHMENT ||
            messageFile.type === MessageFinAttachmentTypes.FILE_ATTACHMENT) &&
            !Files.isImage(messageFile.file) ? (
            <ItemMessagesListMessageRepliedMessageFile
              key={messageFile.uuid}
              fileAttachment={messageFile}
              hiddenFilesCount={
                index === lastShownFilesIndex
                  ? messageFilesCount - showFilesCount
                  : undefined
              }
            />
          ) : (
            <ItemMessagesListMessageRepliedMessageImage
              key={messageFile.uuid}
              attachment={messageFile}
              hiddenFilesCount={
                index === lastShownFilesIndex
                  ? messageFilesCount - showFilesCount
                  : undefined
              }
            />
          );
        })}
      </div>
    </div>
  );
}

export default ItemMessagesListMessageRepliedMessageAttachments;
